import {Component, OnInit} from '@angular/core';
import {NbDialogService} from '@nebular/theme';
import {DeactivateDialogComponent} from './deactivate-dialog/deactivate-dialog.component';
import {SellerDeactivationComponent} from './seller-deactivation-dialog/seller-deactivation.component';
import {IntegrationChangeLogComponent} from './integration-change-log-dialog/integration-change-log.component';
import {StoreService} from '../../../../core/src/lib/store/store.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { APISeller } from "../../../../core/src/lib/api/api.types";
import { Router } from "@angular/router";

@Component({
  selector: 'ngx-settings',
  styleUrls: ['./settings.component.scss'],
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit{
  private alive = true;
  active = true;
  isAdmin = true;
  seller!: APISeller;

  oloIntegrationForm = new FormGroup({
    clientSecret: new FormControl('', [Validators.required]),
    opsClientID: new FormControl('', [Validators.required]),
    opsClientSecret: new FormControl('', [Validators.required]),
    active: new FormControl(false)
  })

  private oloIntegrationID?: number

  constructor(
    private dialogService: NbDialogService,
    public store: StoreService,
    private router: Router
  ) {}

  ngOnInit() {
    this.store.user$.subscribe(user => {
if (user) {
        this.isAdmin = user.role.permissionLevel >= 2;
      }
    })
    this.store.seller$.subscribe(seller => {
      if (seller) {
        this.seller = seller;
      } else {
        this.router.navigate(['pages', 'manage-sellers']);
      }
    })

    this.store.oloIntegration$.subscribe(oloIntegration => {
      if (oloIntegration) {
        this.oloIntegrationID = oloIntegration.id
        this.oloIntegrationForm.setValue({
          clientSecret: oloIntegration.clientSecret,
          opsClientID: oloIntegration.opsClientID,
          opsClientSecret: oloIntegration.opsClientSecret,
          active: oloIntegration.active
        })
      }
    })
  }

  saveChanges(event: Event) {
    event.stopPropagation();
    if (!this.oloActive?.value) {
      const dialogRef = this.dialogService.open(DeactivateDialogComponent, {
        context: {
          title: 'Deactivate Integration',
          sellerName: this.seller.name,
        },
      });
      dialogRef.componentRef.instance.deactivate.subscribe(() => {
        this.store.updateOloIntegration(this.oloIntegrationForm.value, this.seller.id).subscribe();
      })
    } else {
      this.store.updateOloIntegration(this.oloIntegrationForm.value, this.seller.id).subscribe();
    }
  }

  sellerRequestDeactivation(event: Event) {
    event.stopPropagation();
    const dialogRef = this.dialogService.open(SellerDeactivationComponent, {
      context: {
        title: 'Deactivate Integration',
        sellerName: this.seller.name
      },
    });
    dialogRef.componentRef.instance.deactivate.subscribe(() => {
      this.store.requestIntegrationShutoff('olo', this.seller.id).subscribe();
    })
  }

  changeLog(event: Event) {
    event.stopPropagation();
    this.dialogService.open(IntegrationChangeLogComponent, {
      context: {
        title: 'Integration Changelog',
        integrationId: this.oloIntegrationID
      },
    });
  }

  get oloClientSecret() {
    return this.oloIntegrationForm.get('clientSecret');
  }

  get oloActive() {
    return this.oloIntegrationForm.get('active');
  }

}
