import { Component } from '@angular/core';
import { NbLoginComponent } from '@nebular/auth';

@Component({
  selector: 'password-login',
  templateUrl: './password-login.component.html',
  styleUrls: ['password-login.component.scss'],
})
export class PasswordLoginComponent extends NbLoginComponent {
}
