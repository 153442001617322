import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import {CreateAccountComponent} from '../../../../libs/pages/src/lib/auth/create-account/create-account.component';
import {OAuth2LoginComponent} from '../../../../libs/pages/src/lib/auth/oauth2-login/oauth2-login.component';
import {OAuth2CallbackComponent} from '../../../../libs/pages/src/lib/auth/oauth2-callback/oauth2-callback.component';
import { PostAuthGuard } from "../../../../libs/pages/src/lib/guards/post-auth.guard";
import { ResetPasswordComponent } from "../../../../libs/pages/src/lib/auth/reset-password/reset-password.component";
import { PasswordLoginComponent } from "../../../../libs/pages/src/lib/auth/password-login/password-login.component";
import { LogoutComponent } from "../../../../libs/pages/src/lib/auth/logout/logout.component";

export const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('../../../../libs/pages/src/lib/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: NbLoginComponent,
      },
      {
        path: 'login',
        component: PasswordLoginComponent,
        canActivate: [PostAuthGuard]
      },
      {
        path: 'register',
        component: CreateAccountComponent,
        canActivate: [PostAuthGuard]
      },
      {
        path: 'logout',
        component: LogoutComponent,
      },
      {
        path: 'request-password',
        component: NbRequestPasswordComponent,
      },
      {
        path: 'reset-password/:identity/:token',
        component: ResetPasswordComponent,
      },
      {
        path: 'oauth2',
        component: OAuth2LoginComponent,
      },
      {
        path: 'oauth2/callback',
        component: OAuth2CallbackComponent,
      }
    ],
  },
  { path: '', redirectTo: '/pages/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/pages/404' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
