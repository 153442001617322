import { Component, Input, OnInit } from "@angular/core";
import {NbDialogService} from '@nebular/theme';
import {ResetPasswordDialogComponent} from './reset-password-dialog/reset-password-dialog.component';
import { StoreService } from "../../../../core/src/lib/store/store.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'ngx-users',
  styleUrls: ['./user-information.component.scss'],
  templateUrl: './user-information.component.html',
})
export class UserInformationComponent implements OnInit{
  userName?: string;
  superadmin = false;
  isSelf = false;
  currentEmail = '';

  userForm = new FormGroup(
    {
      id: new FormControl(null),
      firstName: new FormControl('', [Validators.required, Validators.pattern('^[^0-9_!¡?÷?¿/\\\\+=@#$%ˆ&*(){}|~<>;:[\\]]{2,}$')]),
      lastName: new FormControl('', [Validators.required, Validators.pattern('^[^0-9_!¡?÷?¿/\\\\+=@#$%ˆ&*(){}|~<>;:[\\]]{2,}$')]),
      email: new FormControl('', [Validators.required, Validators.email]),
      role: new FormControl(null, [Validators.required]),
      active: new FormControl(0)
    }
  )

  saveLoading = false;

  constructor(
    private dialogService: NbDialogService,
    public store: StoreService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params["id"]) {
        this.store.getUser(params["id"]).subscribe( user => {
          this.currentEmail = user.email;
          this.userName = user.first_name + ' ' + user.last_name;
          this.superadmin = user.role.permissionLevel === 3;
          this.userForm.setValue({
            id: user.id,
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            role: user.role.id,
            active: user.active ? 1 : 0
          })
          this.isSelf = user.id === this.store.user?.id;
        })
      } else {
        this.store.user$.subscribe(user => {
          if (user) {
            this.currentEmail = user.email;
            this.userName = user.first_name + ' ' + user.last_name;
            this.superadmin = user.role.permissionLevel === 3;
            this.userForm.setValue({
              id: user.id,
              firstName: user.first_name,
              lastName: user.last_name,
              email: user.email,
              role: user.role.id,
              active: user.active ? 1 : 0
            })
          }
        })
      }
    })

  }

  saveChanges() {
    this.saveLoading = true;
    this.store.updateUser(
      this.id,
      this.firstName,
      this.lastName,
      this.email,
      this.role,
      !!this.active,
      this.active === -1
    ).subscribe(() => {
      this.saveLoading = false;
      this.router.navigate(['/pages/users']);
    })
  }


  resetPassword(event: Event) {
    event.stopPropagation();
    this.store.resetPassword(this.currentEmail).subscribe(() => {
      this.dialogService.open(ResetPasswordDialogComponent, {
        context: {
          title: 'Reset Password',
          //Update to be dynamic based on the seller of the card that was selected
          sellerName: this.userName,
          //Update to be dynamic based on the sellers email address
          emailAddress: this.currentEmail,
        },
      });
    })
  }

  private get id() {
    return this.userForm.get('id')?.value;
  }

  private get firstName() {
    return this.userForm.get('firstName')?.value;
  }

  private get lastName() {
    return this.userForm.get('lastName')?.value;
  }

  private get email() {
    return this.userForm.get('email')?.value;
  }

  private get role() {
    return this.userForm.get('role')?.value;
  }

  private get active() {
    return this.userForm.get('active')?.value;
  }
}
