import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, takeWhile} from 'rxjs/operators';
import {User} from '../../../../core/src/lib/data/admin-panel';
import {AdminPanelService} from '../../../../core/src/lib/mock/admin-panel.service';
import { StoreService } from "../../../../core/src/lib/store/store.service";
import { APISeller } from "../../../../core/src/lib/api/api.types";
import { Router } from "@angular/router";

@Component({
  selector: 'ngx-manage-sellers',
  styleUrls: ['./manage-sellers.component.scss'],
  templateUrl: './manage-sellers.component.html',
})
export class ManageSellersComponent implements OnInit, OnDestroy {

  private alive = true;
  active = true;

  options: APISeller[] = [];
  filteredOptions$?: Observable<APISeller[]>;

  superadmin = true;

  @ViewChild('autoInput') input?: ElementRef<HTMLInputElement>;

  constructor(public store: StoreService, private router: Router) {
  }

  ngOnInit() {
    this.store.user$.pipe(takeWhile(() => this.alive)).subscribe(user => {
      this.superadmin = user?.role.permissionLevel === 3;
      this.options = this.store.availableSellers
    })

  }

  ngOnDestroy() {
    this.alive = false;
  }

  private filter(value: string): APISeller[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(optionValue => optionValue.name.toLowerCase().includes(filterValue));
  }

  getFilteredOptions(value: string): Observable<APISeller[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onChange() {
    this.filteredOptions$ = this.getFilteredOptions(this.input?.nativeElement.value ?? '');
  }

  onSelectionChange($event: APISeller) {
    this.sellerSettings($event)
  }

  sellerSettings(seller: APISeller) {
    this.store.setSeller(seller);
    this.router.navigate(['pages', 'settings']);
  }

  selectSeller(seller: APISeller) {
    this.store.setSeller(seller);
    this.router.navigate(['pages', 'home']);
  }
}
