import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  getDeepFromObject,
  NbAuthJWTToken,
  NbAuthModule, NbAuthToken,
  NbDummyAuthStrategy,
  NbPasswordAuthStrategy,
  NbPasswordAuthStrategyOptions, NbTokenLocalStorage, NbTokenStorage
} from "@nebular/auth";
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
  SeoService,
  StateService,
} from './utils';
import { MockDataModule } from './mock/mock-data.module';
import {AdminPanelData} from './data/admin-panel';
import {AdminPanelService} from './mock/admin-panel.service';
import {HttpService} from './api/http.service';
import {ApiService} from './api/api.service';
import {ApiModule} from './api/api.module';
import {HttpErrorResponse} from '@angular/common/http';

// const socialLinks = [
//   {
//     url: 'https://github.com/akveo/nebular',
//     target: '_blank',
//     icon: 'github',
//   },
//   {
//     url: 'https://www.facebook.com/akveo/',
//     target: '_blank',
//     icon: 'facebook',
//   },
//   {
//     url: 'https://twitter.com/akveo_inc',
//     target: '_blank',
//     icon: 'twitter',
//   },
// ];

const DATA_SERVICES = [
  { provide: AdminPanelData, useClass: AdminPanelService },
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const NB_CORE_PROVIDERS = [
  ...DATA_SERVICES,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ...NbAuthModule.forRoot({

    strategies: [
      // NbDummyAuthStrategy.setup({
      //   name: 'email',
      //   delay: 3000,
      //
      // }),
      NbPasswordAuthStrategy.setup({
        name: 'email',
        baseEndpoint: `${window.location.host.includes('localhost') ? 'http://localhost:3333' : window.location.origin}/api`,
        login: {
          endpoint: '/auth/login',
          method: 'post',
          redirect: {
            success: '/pages/home',
          },
        },
        logout: {
          endpoint: '/',
          method: 'get',
          redirect: {
            success: '/auth/login',
            failure: '/auth/login',
          }
        },
        register: {
          endpoint: '/auth/create-account',
          method: 'post',
        },
        requestPass: {
          endpoint: '/auth/forgot-password',
          method: 'post',
        },
        resetPass: {
          redirect: { success: "/auth/login" },
          endpoint: '/auth/reset-password',
          method: 'post',
        },
        token: {
          class: NbAuthJWTToken,
          key: 'token', // this parameter tells where to look for the token,
        },
        errors: {
          key: 'message', // this parameter tells where to look for the error message
        },
        validation: {
          password: {
            required: true,
            minLength: 8,
          }
        }
      })
    ],
    forms: {
      register: {
        terms: false
      },
      login: {
        rememberMe: false,
      }
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      seller: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,
  {provide: NbTokenStorage, useClass: NbTokenLocalStorage},

  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
  LayoutService,
  SeoService,
  StateService,
];

@NgModule({
  imports: [
    CommonModule,
    ApiModule
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
