import { NgModule } from '@angular/core';
import {NbAutocompleteModule, NbButtonModule, NbCardModule, NbDialogModule, NbInputModule, NbToggleModule} from '@nebular/theme';

import { ThemeModule } from '@square-pos-integration/theme';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import {NgxEchartsModule} from 'ngx-echarts';
import {EchartsLineComponent} from '../charts/echarts/echarts-line.component';
import {EchartsPieComponent} from '../charts/echarts/echarts-pie.component';
import {EchartsBarComponent} from '../charts/echarts/echarts-bar.component';
import {EchartsMultipleXaxisComponent} from '../charts/echarts/echarts-multiple-xaxis.component';
import {EchartsAreaStackComponent} from '../charts/echarts/echarts-area-stack.component';
import {EchartsBarAnimationComponent} from '../charts/echarts/echarts-bar-animation.component';
import {EchartsRadarComponent} from '../charts/echarts/echarts-radar.component';
import {InactiveIntegrationDialogComponent} from './inactive-integration-dialog/inactive-integration-dialog.component';

const COMPONENTS = [
  InactiveIntegrationDialogComponent,
];

const ENTRY_COMPONENTS = [
  InactiveIntegrationDialogComponent,
];

const MODULES = [
  NbDialogModule.forChild(),
];

// const SERVICES = [
// ];

@NgModule({
  imports: [
    ThemeModule,
    NbCardModule,
    NbButtonModule,
    HomeRoutingModule,
    NbAutocompleteModule,
    NbInputModule,
    NbToggleModule,
    NbButtonModule,


    NgxEchartsModule.forRoot({
      echarts: () => import("echarts")
    }),
  ],
  declarations: [
    HomeComponent,
    EchartsLineComponent,
    EchartsPieComponent,
    EchartsBarComponent,
    EchartsMultipleXaxisComponent,
    EchartsAreaStackComponent,
    EchartsBarAnimationComponent,
    EchartsRadarComponent,
    InactiveIntegrationDialogComponent,
  ],
  // providers: [
  //   ...SERVICES,
  // ],
  entryComponents: [
    ...ENTRY_COMPONENTS,
  ],
})
export class HomeModule { }
