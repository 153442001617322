import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { NB_AUTH_OPTIONS, NbAuthService, NbRegisterComponent, NbResetPasswordComponent } from "@nebular/auth";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'square-pos-integration-create-account',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent extends NbResetPasswordComponent implements OnInit {

  constructor(protected override service: NbAuthService,
              @Inject(NB_AUTH_OPTIONS) protected override options = {},
              protected override cd: ChangeDetectorRef,
              protected override router: Router,
              private route: ActivatedRoute
              ) {
    super(service, options, cd, router);
    this.redirectDelay = this.getConfigValue('forms.resetPassword.redirectDelay');
    this.showMessages = this.getConfigValue('forms.resetPassword.showMessages');
    this.strategy = this.getConfigValue('forms.resetPassword.strategy');
  }
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.user['identity'] = params["identity"];
      this.user['token'] = params["token"];
    })
  }
}
