import { NgModule } from '@angular/core';
import {
  NbActionsModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbCardModule,
  NbIconModule,
  NbInputModule,
  NbTagModule,
} from '@nebular/theme';

import { ThemeModule } from '@square-pos-integration/theme';
import { ManageSellersRoutingModule } from './manage-sellers-routing.module';
import { ManageSellersComponent } from './manage-sellers.component';

@NgModule({
  imports: [
    ThemeModule,
    NbCardModule,
    NbButtonModule,
    ManageSellersRoutingModule,
    NbCardModule,
    NbTagModule,
    NbActionsModule,
    NbIconModule,
    NbInputModule,
    NbAutocompleteModule,
    NbActionsModule
  ],
  declarations: [
    ManageSellersComponent,
    ManageSellersComponent,
  ],
})
export class ManageSellersModule { }
