import { NgModule, Provider } from '@angular/core';
import {
  NbAccordionModule,
  NbButtonModule,
  NbCardModule,
  NbTagModule,
  NbInputModule,
  NbDialogModule,
  NbToggleModule, NbTreeGridModule, NbSpinnerModule
} from "@nebular/theme";

import { ThemeModule } from '@square-pos-integration/theme';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';
import { DeactivateDialogComponent } from './deactivate-dialog/deactivate-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SellerDeactivationComponent} from './seller-deactivation-dialog/seller-deactivation.component';
import {IntegrationChangeLogComponent} from './integration-change-log-dialog/integration-change-log.component';

const COMPONENTS = [
  DeactivateDialogComponent,
  SellerDeactivationComponent,
  IntegrationChangeLogComponent,
];

const ENTRY_COMPONENTS = [
  DeactivateDialogComponent,
  SellerDeactivationComponent,
  IntegrationChangeLogComponent,
];

const MODULES = [
  NbDialogModule.forChild(),
];

const SERVICES: Provider[] = [
];

@NgModule({
  imports: [
    ThemeModule,
    NbCardModule,
    NbButtonModule,
    SettingsRoutingModule,
    NbCardModule,
    NbAccordionModule,
    NbTagModule,
    NbInputModule,
    NbDialogModule,
    ...MODULES,
    NbToggleModule,
    FormsModule,
    NbTreeGridModule,
    ReactiveFormsModule,
    NbSpinnerModule
  ],
  declarations: [
    SettingsComponent,
    DeactivateDialogComponent,
    SellerDeactivationComponent,
    IntegrationChangeLogComponent,
    ...COMPONENTS,
  ],
  providers: [
    ...SERVICES,
  ],
  entryComponents: [
    ...ENTRY_COMPONENTS,
  ],
})

export class SettingsModule { }
