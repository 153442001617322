import {NbMenuItem} from '@nebular/theme';

export const SELLER_MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Home',
    icon: 'home-outline',
    link: '/pages/home',
    home: true,
  },
  {
    title: 'Manage Locations',
    icon: 'pin-outline',
    link: '/pages/manage-locations',
  },
  {
    title: 'Settings',
    icon: 'settings-2',
    link: '/pages/settings',
  },
  {
    title: 'Your Account',
    icon: 'person-outline',
    children: [
      {
        title: 'User Information',
        icon: 'info-outline',
        link: '/pages/user-information',
      },
      {
        title: 'Log Out',
        icon: 'log-out-outline',
        link: '/auth/logout',
      },
    ]
  }
];
export const ACCOUNT_MANAGER_MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Home',
    icon: 'home-outline',
    link: '/pages/home',
    home: true,
  },
  {
    title: 'Manage Sellers',
    icon: 'briefcase-outline',
    link: '/pages/manage-sellers',
  },
  {
    title: 'Manage Locations',
    icon: 'pin-outline',
    link: '/pages/manage-locations',
  },
  {
    title: 'Settings',
    icon: 'settings-2',
    link: '/pages/settings',
  },
  {
    title: 'Your Account',
    icon: 'person-outline',
    children: [
      {
        title: 'User Information',
        icon: 'info-outline',
        link: '/pages/user-information',
      },
      {
        title: 'Log Out',
        icon: 'log-out-outline',
        link: '/auth/logout',
      },
    ]
  }
];

export const ADMIN_MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Home',
    icon: 'home-outline',
    link: '/pages/home',
    home: true,
  },
  {
    title: 'Manage Sellers',
    icon: 'briefcase-outline',
    link: '/pages/manage-sellers',
  },
  {
    title: 'Manage Locations',
    icon: 'pin-outline',
    link: '/pages/manage-locations',
  },
  {
    title: 'Users',
    icon: 'people-outline',
    link: '/pages/users',
  },
  {
    title: 'Settings',
    icon: 'settings-2',
    link: '/pages/settings',
  },
  {
    title: 'Your Account',
    icon: 'person-outline',
    children: [
      {
        title: 'User Information',
        icon: 'info-outline',
        link: '/pages/user-information',
      },
      {
        title: 'Log Out',
        icon: 'log-out-outline',
        link: '/auth/logout',
      },
    ]
  }
];
