import {Observable} from 'rxjs';
import {GetUserResponse, SellerByIDResponse} from '@libs/api-models';

export interface Action {
  id: number;
  name: string;
  minPermissionLevel: number;
  activity: Activity[];
}

export interface Activity {
  id: number;
  timestamp: Date;
  actionID: number;
  userID: number;
  action: Action;
  user: User;
}

export interface OloIntegration {
  id: number;
  sellerID: number;
  clientSecret: string;
  seller: Seller;
}

export interface Order {
  id: number;
  integrationOrderID: string;
  integrationOrderData: string;
  integrationOrderTotalCents: number;
  squareOrderID: string;
  squareOrderData: string;
  squareOrderTotalCents: number;
  success: boolean;
  timestamp: Date;
  sellerID: number;
  seller: Seller;
}

export interface Role {
  id: number;
  permissionLevel: number;
  name: string;
  user: User[];
}

export interface Seller {
  id: number;
  name: string;
  projectKey: string;
  accessToken: string;
  adminID: number;
  user: User;
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  passwordHash: string;
  active: boolean;
  roleID: number;
  activity: Activity[];
  seller: Seller[];
  role: Role;
}

export abstract class AdminPanelData {
  abstract getSellerData(): Observable<SellerByIDResponse>;
  abstract getUserData(): Observable<GetUserResponse>;
  abstract getSuperAdminData(): Observable<User[]>;
}
