import { NgModule } from '@angular/core';
import {NbButtonModule, NbCardModule, NbInputModule, NbSelectModule} from '@nebular/theme';

import { ThemeModule } from '@square-pos-integration/theme';
import { NewUserRoutingModule } from './new-user-routing.module';
import { NewUserComponent } from './new-user.component';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  imports: [
    ThemeModule,
    NbCardModule,
    NbButtonModule,
    NewUserRoutingModule,
    NbCardModule,
    NbInputModule,
    NbButtonModule,
    NbSelectModule,
    ReactiveFormsModule
  ],
  declarations: [
    NewUserComponent,
  ],
})
export class NewUserModule { }
