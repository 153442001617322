import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {from, Observable, of, switchMap} from 'rxjs';
import {StoreService} from '../../../../core/src/lib/store/store.service';
import {NbTokenService} from '@nebular/auth';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate, CanActivateChild {

  constructor(private store: StoreService, private router: Router) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.user$.pipe(switchMap(user => {
      if (user && user.role.permissionLevel === 1) {
        return from(this.router.navigate(['pages', '404']).then((_) => false))
      }
      return of(true)
    }))
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.user$.pipe(switchMap(user => {
      if (user && user.role.permissionLevel === 1) {
        return from(this.router.navigate(['pages', '404']).then((_) => false))
      }
      return of(true)
    }))
  }

}
