import {AfterViewInit, Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {NbJSThemeVariable, NbThemeService} from '@nebular/theme';
import {Order} from '@libs/api-models';
import * as moment from 'moment';

@Component({
  selector: 'ngx-echarts-area-stack',
  template: `
    <div echarts [options]="options" class="echart"></div>
  `,
})
export class EchartsAreaStackComponent implements AfterViewInit, OnDestroy, OnChanges {
  private colors: NbJSThemeVariable | undefined;
  private echarts: NbJSThemeVariable | undefined;
  options: any = {};
  themeSubscription: any;
  @Input() allOrders!: Order[]
  last30Days: string[]= [];
  isViewInitialized = false;

  constructor(private theme: NbThemeService) {
    const firstDay = moment().subtract(30, 'days')
    while (firstDay.isSameOrBefore(moment(), 'day')) {
      this.last30Days.push(firstDay.format('YYYY-MM-DD'))
      firstDay.add(1, 'days')
    }
  }

  ngAfterViewInit() {
    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

      this.colors = config.variables;
      this.echarts = (config.variables?.['echarts'] as NbJSThemeVariable);
      this.isViewInitialized = true;
      this.setOptions(this.allOrders);

    });
  }

  ngOnChanges() {
    if (this.isViewInitialized) {
      this.setOptions(this.allOrders);
    }
  }

  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
  }

  private setOptions(orders: Order[]) {
    const successfulOrders = this.sortAndCombineByDate(orders.filter(order => order.success));
    const failedOrders = this.sortAndCombineByDate(orders.filter(order => !order.success));
    if (this.colors && this.echarts) {
      this.options = {
        backgroundColor: this.echarts['bg'],
        color: [this.colors['dangerLight'], this.colors['successLight']],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: this.echarts['tooltipBackgroundColor'],
            },
          },
        },
        legend: {
          data: ['Failed Orders', 'Successful Orders'],
          textStyle: {
            color: this.echarts['textColor'],
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: this.last30Days,
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              lineStyle: {
                color: this.echarts['axisLineColor'],
              },
            },
            axisLabel: {
              textStyle: {
                color: this.echarts['textColor'],
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              lineStyle: {
                color: this.echarts['axisLineColor'],
              },
            },
            splitLine: {
              lineStyle: {
                color: this.echarts['splitLineColor'],
              },
            },
            axisLabel: {
              textStyle: {
                color: this.echarts['textColor'],
              },
            },
          },
        ],
        series: [
          {
            name: 'Failed Orders',
            type: 'line',
            stack: 'Total amount',
            areaStyle: {normal: {opacity: this.echarts['areaOpacity']}},
            data: this.last30Days.map(date => failedOrders.find((order) => order.date === date)?.orders.length || 0),
          },
          {
            name: 'Successful Orders',
            type: 'line',
            stack: 'Total amount',
            areaStyle: {normal: {opacity: this.echarts['areaOpacity']}},
            data: this.last30Days.map(date => successfulOrders.find((order) => order.date === date)?.orders.length || 0),
          },
        ],
      };
    }
  }

  private sortAndCombineByDate(orders: Order[]) {
    const sortedOrders = orders.sort((a, b) => {
      return moment(a.timestamp).isBefore(b.timestamp) ? -1 : 1;
    });
    const groupedOrders = sortedOrders.reduce((acc, order) => {
      const date = moment(order.timestamp).format('YYYY-MM-DD');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(order);
      return acc;
    }, {} as {[key: string]: Order[]});
    const combinedOrders = Object.keys(groupedOrders).map(key => {
      return {
        date: key,
        orders: groupedOrders[key],
      };
    });
    return combinedOrders;
  }
}
