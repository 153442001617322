import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private baseUrl = `${window.location.host.includes('localhost') ? 'http://localhost:3333' : window.location.origin}/api`;

  constructor(
    private http: HttpClient,
  ) { }

  get<T>(resource: string, token?: string, queryParams: HttpParams = new HttpParams()): Observable<T> {
    return this.http.get<T>(this.baseUrl.concat(resource), this.getHttpOptions(token, queryParams));
  }

  getFile(resource: string, token?: string, queryParams: HttpParams = new HttpParams()): Observable<Blob> {
    return this.http.get(this.baseUrl.concat(resource), this.getFileHttpOptions(token, queryParams));
  }

  post<T, K>(resource: string, body: K, token?: string, queryParams: HttpParams = new HttpParams()): Observable<T> {
    return this.http.post<T>(this.baseUrl.concat(resource), body, this.getHttpOptions(token, queryParams));
  }

  put<T, K>(resource: string, body: K, token?: string, queryParams: HttpParams = new HttpParams()): Observable<T> {
    return this.http.put<T>(this.baseUrl.concat(resource), body, this.getHttpOptions(token, queryParams));
  }

  private getHeaders(token?: string): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/json');
    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  }

  private getHttpOptions(token?: string, queryParams?: HttpParams): { headers: HttpHeaders; responseType: 'json'; params: HttpParams | undefined; observe: 'body' } {
    return {
      headers: this.getHeaders(token),
      params: queryParams,
      observe: 'body',
      responseType: 'json',
    };
  }

  getFileHttpOptions(token?: string, queryParams?: HttpParams): { headers: HttpHeaders; responseType: 'blob'; params: HttpParams | undefined; } {
    return {
      headers: this.getHeaders(token),
      params: queryParams,
      responseType: 'blob',
    };
  }
}
