import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit, TemplateRef,
  ViewChild
} from "@angular/core";
import {
  NbDialogRef,
  NbDialogService,
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService
} from "@nebular/theme";

import { LayoutService } from '../../../../../core/src/lib/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { StoreService } from '../../../../../core/src/lib/store/store.service';
import {SellerLocation, SquareSeller, User} from '@libs/api-models';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user?: User;
  title: any;

  sellers: SquareSeller[] = [];
  filteredOptions$: Observable<SquareSeller[]> = of([]);

  locations: SellerLocation[] = [];
  filteredLocations$: Observable<SellerLocation[]> = of([]);

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'corporate';

  userMenu = [{ title: 'User Information', link: '/pages/user-information' }, { title: 'Log Out', link: '/auth/logout' }];

  @ViewChild('autoInputSellers') sellerInput!: ElementRef<HTMLInputElement>;
  @ViewChild('autoInputLocations') locationInput!: ElementRef<HTMLInputElement>;
  @ViewChild('locationIDModal') locationIDModal!: TemplateRef<any>;

  modalRef: NbDialogRef<TemplateRef<any>>

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private store: StoreService,
    private modal: NbDialogService,
  ) {}

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.filteredOptions$ = of(this.sellers);

    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => this.user = users.nick);
    this.store.user$.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });

    this.store.availableSellers$.subscribe((sellers) => {
      this.sellers = sellers;
    });

    this.store.sellerLocations$.subscribe((locations) => {
      this.locations = locations;
    })

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private filterSellers(value: string): SquareSeller[] {
    const filterValue = value.toLowerCase();
    return this.sellers.filter((optionValue) =>
      optionValue.name.toLowerCase().includes(filterValue)
    );
  }

  getFilteredSellers(value: string): Observable<SquareSeller[]> {
    return of(value).pipe(map((filterString) => this.filterSellers(filterString)));
  }

  private filterLocations(value: string): SellerLocation[] {
    const filterValue = value.toLowerCase();
    return this.locations.filter((optionValue) =>
      optionValue.name.toLowerCase().includes(filterValue)
    );
  }

  getFilteredLocations(value: string): Observable<SellerLocation[]> {
    return of(value).pipe(map((filterString) => this.filterLocations(filterString)));
  }

  onSellerChange() {
    this.filteredOptions$ = this.getFilteredSellers(
      this.sellerInput.nativeElement.value
    );
  }

  onSellerSelectionChange($event: SquareSeller) {
    this.sellerInput.nativeElement.value = $event.name;
    this.store.setSeller($event);
  }

  openLocationIDModal() {
    this.modalRef = this.modal.open(this.locationIDModal)
  }

  closeLocationIDModal() {
    this.modalRef?.close()
  }

  onLocationChange() {
    this.filteredLocations$ = this.getFilteredLocations(
      this.locationInput.nativeElement.value
    );
  }

  onLocationSelectionChange($event: SellerLocation) {
    this.locationInput.nativeElement.value = $event.name;
    // this.store.setSeller($event);
    // TODO: Set location
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
