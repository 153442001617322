import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {from, Observable, of, switchMap} from 'rxjs';
import {StoreService} from '../../../../core/src/lib/store/store.service';
import {NbTokenService} from '@nebular/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate, CanActivateChild {

  constructor(private token: NbTokenService, private router: Router) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.token.get().pipe(switchMap((token) => {
      if (token && token.isValid()) {
        return of(true)
      }
      return from(this.router.navigate(['auth', 'login']).then((_) => false))
    }))
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.token.get().pipe(switchMap((token) => {
      if (token && token.isValid()) {
        return of(true)
      }
      return from(this.router.navigate(['auth', 'login']).then((_) => false))
    }))
  }

}
