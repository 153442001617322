import {Component, EventEmitter, Input, Output} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-deactivate-dialog',
  templateUrl: 'seller-deactivation.component.html',
  styleUrls: ['seller-deactivation.component.scss'],
})
export class SellerDeactivationComponent {

  isOlo = true;

  @Input() title!: string;
  @Input() sellerName!: string;

  @Output() deactivate = new EventEmitter<void>();

  constructor(protected ref: NbDialogRef<SellerDeactivationComponent>) {}

  dismiss() {
    this.ref.close();
  }

  confirmDeactivation() {
    this.deactivate.emit();
    this.ref.close();
  }
}
