import { Component, OnDestroy } from '@angular/core';
import { NbAuthResult, NbAuthService } from '@nebular/auth';
import {ActivatedRoute, Router} from '@angular/router';
import { map, takeUntil, withLatestFrom } from "rxjs/operators";
import {from, of, Subject, switchMap} from 'rxjs';
import {StoreService} from '../../../../../core/src/lib/store/store.service';

@Component({
  selector: 'nb-playground-oauth2-callback',
  template: `
    <nb-layout>
      <nb-layout-column>{{message}}</nb-layout-column>
    </nb-layout>
  `,
})
export class OAuth2CallbackComponent implements OnDestroy {

  private destroy$ = new Subject<void>();

  public message = 'Authenticating...';

  constructor(private route: ActivatedRoute, private router: Router, private store: StoreService) {
    this.route.queryParams.pipe(takeUntil(this.destroy$), switchMap((params) => {
      if (params['code'] && params['state'] === localStorage.getItem('state')) {
        this.message = 'Connecting accounts...'
        return this.store.authorizeSeller(params['code']).pipe(withLatestFrom(this.store.user$),switchMap(([response, user]) => {
          if (response.success && user) {
            return this.store.updateUser(user.id, user.first_name, user.last_name, user.email, user.role.id, user.active, false).pipe(map(() => {
              this.message = 'Account authorized. Redirecting...';
              setTimeout(() => {
                this.router.navigateByUrl('/').then(() => window.location.reload());
                // window.open(window.location.origin, '_self', 'noopener,noreferrer,');
              }, 1000);
              return
            }))
          } else {
            this.message = 'Could not connect accounts. Please try again.';
            setTimeout(() => {
              this.router.navigate(['auth','oauth2']);
              }, 1000);
            return
          }
        }))
      } else {
        this.message = 'Account not authorized. Please try again.';
        setTimeout(() => {
          this.router.navigate(['auth','oauth2']);
        }, 1000);
        return
      }
    })).subscribe(() => localStorage.removeItem('state'));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
