import {Component} from '@angular/core';
import {ApiService} from '../../../../core/src/lib/api/api.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { StoreService } from "../../../../core/src/lib/store/store.service";
import { Router } from "@angular/router";

@Component({
  selector: 'ngx-new-user',
  styleUrls: ['./new-user.component.scss'],
  templateUrl: './new-user.component.html',
})
export class NewUserComponent {
  createUserLoading = false;

  createAccountForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    firstName: new FormControl('', [Validators.required, Validators.pattern('^[^0-9_!¡?÷?¿/\\\\+=@#$%ˆ&*(){}|~<>;:[\\]]{2,}$')]),
    lastName: new FormControl('', [Validators.required, Validators.pattern('^[^0-9_!¡?÷?¿/\\\\+=@#$%ˆ&*(){}|~<>;:[\\]]{2,}$')]),
    roleID: new FormControl(2, [Validators.required]),
  });
  constructor(
    private store: StoreService,
    private router: Router,
  ) {
  }
  createUser() {
    this.store.createUser(
      this.createAccountForm.value.firstName,
      this.createAccountForm.value.lastName,
      this.createAccountForm.value.email,
      this.createAccountForm.value.roleID
    ).subscribe(() => {
      this.createUserLoading = false;
      this.router.navigate(['/pages/users'])
    });
  }

  get email() {
    return this.createAccountForm.get('email');
  }

  get firstName() {
    return this.createAccountForm.get('firstName');
  }

  get lastName() {
    return this.createAccountForm.get('lastName');
  }

  get roleID() {
    return this.createAccountForm.get('roleID');
  }
}
