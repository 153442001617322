import { Component } from '@angular/core';

import { SELLER_MENU_ITEMS, ADMIN_MENU_ITEMS, ACCOUNT_MANAGER_MENU_ITEMS } from "./pages-menu";
import {ActivatedRoute} from '@angular/router';
import { StoreService } from "../../../core/src/lib/store/store.service";

@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  template: `
    <ngx-one-column-layout>
      <nb-menu [items]="menu"></nb-menu>
      <router-outlet></router-outlet>
    </ngx-one-column-layout>
  `,
})
export class PagesComponent {
  menu = SELLER_MENU_ITEMS
  constructor(
    private store: StoreService
  ) {
    this.store.user$.subscribe(user => {
      if (user) {
        switch (user.role.permissionLevel) {
          case 1:
            this.menu = SELLER_MENU_ITEMS;
            break;
          case 2:
            this.menu = ACCOUNT_MANAGER_MENU_ITEMS;
            break;
          case 3:
            this.menu = ADMIN_MENU_ITEMS;
            break;
        }
      }
    })
  }
}
