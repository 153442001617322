import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { PeriodsService } from './periods.service';
// import { UserActive } from '../data/user-activity';
import { AdminPanelData, Seller, User } from '../data/admin-panel';
import { GetUserResponse, SellerByIDResponse } from '@libs/api-models';

@Injectable()
export class AdminPanelService {
  // private getRandom = (roundTo: number) => Math.round(Math.random() * roundTo);
  // private generateUserActivityRandomData(date) {
  //   return {
  //     date,
  //     pagesVisitCount: this.getRandom(1000),
  //     deltaUp: this.getRandom(1) % 2 === 0,
  //     newVisits: this.getRandom(100),
  //   };
  // }
  //
  // // data = {};
  //
  // constructor(private periods: PeriodsService) {
  //   super();
  //   // this.data = {
  //   //   week: this.getDataWeek(),
  //   //   month: this.getDataMonth(),
  //   //   year: this.getDataYear(),
  //   // };
  // }
  //
  // // private getDataWeek(): UserActive[] {
  // //   return this.periods.getWeeks().map((week) => {
  // //     return this.generateUserActivityRandomData(week);
  // //   });
  // // }
  // //
  // // private getDataMonth(): UserActive[] {
  // //   const currentDate = new Date();
  // //   const days = currentDate.getDate();
  // //   const month = this.periods.getMonths()[currentDate.getMonth()];
  // //
  // //   return Array.from(Array(days)).map((_, index) => {
  // //     const date = `${index + 1} ${month}`;
  // //
  // //     return this.generateUserActivityRandomData(date);
  // //   });
  // // }
  // //
  // // private getDataYear(): UserActive[] {
  // //   return this.periods.getYears().map((year) => {
  // //     return this.generateUserActivityRandomData(year);
  // //   });
  // // }
  //
  // getSellerData(): Observable<SellerByIDResponse> {
  //   return observableOf({
  //     id: 1,
  //     name: 'DineEngine',
  //   });
  // }
  //
  // getUserData(): Observable<GetUserResponse> {
  //   const user: GetUserResponse = {
  //     email: '',
  //     first_name: '',
  //     id: 0,
  //     last_name: '',
  //     role: {
  //       id: 2,
  //       name: 'Admin',
  //       permissionLevel: 2,
  //     },
  //   };
  //   return observableOf(user);
  // }
  //
  // getSuperAdminData(): Observable<User[]> {
  //   const users: User[] = [
  //     {
  //       id: 23435,
  //       firstName: 'Nick',
  //       lastName: 'Jones',
  //       email: 'nickjones@example.com',
  //       passwordHash: 'Kj8Jks8JIKU8ZNKJkjna8HI89HK8wkjIJH',
  //       active: true,
  //       roleID: 1,
  //       activity: [],
  //       seller: [
  //         {
  //           id: 984983,
  //           name: 'Nick Jones II',
  //           projectKey: 'hu8@U#JU*H*@3i3jh#IJIJNOk3oij',
  //           accessToken: 'ljnlk2nlk3nlkdnlk2lk3nlr',
  //           adminID: 9820930909209,
  //           oloIntegration: null,
  //           order: [],
  //           user: {
  //             id: 23435,
  //             firstName: 'Nick',
  //             lastName: 'Jones II',
  //             email: 'nickjones2@example.com',
  //             passwordHash: 'Kj8Jks8JIKU8ZNKJkjna8HI89HK8wkjIJH',
  //             active: true,
  //             roleID: 1,
  //             activity: [],
  //             seller: [],
  //             role: null,
  //           },
  //         },
  //         {
  //           id: 984983,
  //           name: 'Rich Jones III',
  //           projectKey: 'hu8@U#JU*H*@3i3jh#IJIJNOk3oij',
  //           accessToken: 'ljnlk2nlk3nlkdnlk2lk3nlr',
  //           adminID: 9820930909209,
  //           oloIntegration: null,
  //           order: [],
  //           user: {
  //             id: 23435,
  //             firstName: 'Rich',
  //             lastName: 'Jones III',
  //             email: 'richjones3@example.com',
  //             passwordHash: 'Kj8Jks8JIKU8ZNKJkjna8HI89HK8wkjIJH',
  //             active: false,
  //             roleID: 1,
  //             activity: [],
  //             seller: [],
  //             role: null,
  //           },
  //         },
  //         {
  //           id: 984983,
  //           name: 'Nick Norris',
  //           projectKey: 'hu8@U#JU*H*@3i3jh#IJIJNOk3oij',
  //           accessToken: 'ljnlk2nlk3nlkdnlk2lk3nlr',
  //           adminID: 9820930909209,
  //           oloIntegration: null,
  //           order: [],
  //           user: {
  //             id: 23435,
  //             firstName: 'Nick',
  //             lastName: 'Norris',
  //             email: 'nicknorris@example.com',
  //             passwordHash: 'Kj8Jks8JIKU8ZNKJkjna8HI89HK8wkjIJH',
  //             active: true,
  //             roleID: 1,
  //             activity: [],
  //             seller: [],
  //             role: null,
  //           },
  //         },
  //       ],
  //       role: null,
  //     },
  //     {
  //       id: 23435,
  //       firstName: 'Nick',
  //       lastName: 'Norris',
  //       email: 'nicknorris@example.com',
  //       passwordHash: 'Kj8Jks8JIKU8ZNKJkjna8HI89HK8wkjIJH',
  //       active: true,
  //       roleID: 1,
  //       activity: [],
  //       seller: [
  //         {
  //           id: 984983,
  //           name: 'Nick Jones II',
  //           projectKey: 'hu8@U#JU*H*@3i3jh#IJIJNOk3oij',
  //           accessToken: 'ljnlk2nlk3nlkdnlk2lk3nlr',
  //           adminID: 9820930909209,
  //           oloIntegration: null,
  //           order: [],
  //           user: {
  //             id: 23435,
  //             firstName: 'Nick',
  //             lastName: 'Jones II',
  //             email: 'nickjones2@example.com',
  //             passwordHash: 'Kj8Jks8JIKU8ZNKJkjna8HI89HK8wkjIJH',
  //             active: true,
  //             roleID: 1,
  //             activity: [],
  //             seller: [],
  //             role: null,
  //           },
  //         },
  //         {
  //           id: 984983,
  //           name: 'Rich Jones III',
  //           projectKey: 'hu8@U#JU*H*@3i3jh#IJIJNOk3oij',
  //           accessToken: 'ljnlk2nlk3nlkdnlk2lk3nlr',
  //           adminID: 9820930909209,
  //           oloIntegration: null,
  //           order: [],
  //           user: {
  //             id: 23435,
  //             firstName: 'Rich',
  //             lastName: 'Jones III',
  //             email: 'richjones3@example.com',
  //             passwordHash: 'Kj8Jks8JIKU8ZNKJkjna8HI89HK8wkjIJH',
  //             active: false,
  //             roleID: 1,
  //             activity: [],
  //             seller: [],
  //             role: null,
  //           },
  //         },
  //         {
  //           id: 984983,
  //           name: 'Nick Norris',
  //           projectKey: 'hu8@U#JU*H*@3i3jh#IJIJNOk3oij',
  //           accessToken: 'ljnlk2nlk3nlkdnlk2lk3nlr',
  //           adminID: 9820930909209,
  //           oloIntegration: null,
  //           order: [],
  //           user: {
  //             id: 23435,
  //             firstName: 'Nick',
  //             lastName: 'Norris',
  //             email: 'nicknorris@example.com',
  //             passwordHash: 'Kj8Jks8JIKU8ZNKJkjna8HI89HK8wkjIJH',
  //             active: true,
  //             roleID: 1,
  //             activity: [],
  //             seller: [],
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       id: 23435,
  //       firstName: 'Rich',
  //       lastName: 'Jones III',
  //       email: 'nickjones@example.com',
  //       passwordHash: 'Kj8Jks8JIKU8ZNKJkjna8HI89HK8wkjIJH',
  //       active: true,
  //       roleID: 1,
  //       activity: [],
  //       seller: [
  //         {
  //           id: 984983,
  //           name: 'Nick Jones II',
  //           projectKey: 'hu8@U#JU*H*@3i3jh#IJIJNOk3oij',
  //           accessToken: 'ljnlk2nlk3nlkdnlk2lk3nlr',
  //           adminID: 9820930909209,
  //           oloIntegration: null,
  //           order: [],
  //           user: {
  //             id: 23435,
  //             firstName: 'Nick',
  //             lastName: 'Jones II',
  //             email: 'nickjones2@example.com',
  //             passwordHash: 'Kj8Jks8JIKU8ZNKJkjna8HI89HK8wkjIJH',
  //             active: true,
  //             roleID: 1,
  //             activity: [],
  //             seller: [],
  //             role: null,
  //           },
  //         },
  //         {
  //           id: 984983,
  //           name: 'Rich Jones III',
  //           projectKey: 'hu8@U#JU*H*@3i3jh#IJIJNOk3oij',
  //           accessToken: 'ljnlk2nlk3nlkdnlk2lk3nlr',
  //           adminID: 9820930909209,
  //           oloIntegration: null,
  //           order: [],
  //           user: {
  //             id: 23435,
  //             firstName: 'Rich',
  //             lastName: 'Jones III',
  //             email: 'richjones3@example.com',
  //             passwordHash: 'Kj8Jks8JIKU8ZNKJkjna8HI89HK8wkjIJH',
  //             active: false,
  //             roleID: 1,
  //             activity: [],
  //             seller: [],
  //             role: null,
  //           },
  //         },
  //         {
  //           id: 984983,
  //           name: 'Nick Norris',
  //           projectKey: 'hu8@U#JU*H*@3i3jh#IJIJNOk3oij',
  //           accessToken: 'ljnlk2nlk3nlkdnlk2lk3nlr',
  //           adminID: 9820930909209,
  //           order: [],
  //           user: {
  //             id: 23435,
  //             firstName: 'Nick',
  //             lastName: 'Norris',
  //             email: 'nicknorris@example.com',
  //             passwordHash: 'Kj8Jks8JIKU8ZNKJkjna8HI89HK8wkjIJH',
  //             active: true,
  //             roleID: 1,
  //             activity: [],
  //             seller: [],
  //             role: null,
  //           },
  //         },
  //       ],
  //       role: null,
  //     },
  //   ];
  //   return observableOf(users);
  // }
}
