import { Component, EventEmitter, Input } from "@angular/core";
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-reset-password-dialog',
  templateUrl: 'reset-password-dialog.component.html',
  styleUrls: ['reset-password-dialog.component.scss'],
})
export class ResetPasswordDialogComponent {

  isOlo = true;

  @Input() title!: string;
  @Input() sellerName!: string;
  @Input() emailAddress!: string;

  continue = new EventEmitter<void>();

  constructor(protected ref: NbDialogRef<ResetPasswordDialogComponent>) {}

  dismiss() {
    this.ref.close();
  }
}
