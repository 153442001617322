import {Component, EventEmitter, Input, Output} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-deactivate-dialog',
  templateUrl: 'deactivate-dialog.component.html',
  styleUrls: ['deactivate-dialog.component.scss'],
})
export class DeactivateDialogComponent {

  isOlo = true;

  @Input() title!: string;
  @Input() sellerName!: string;

  @Output() deactivate = new EventEmitter<void>();
  constructor(protected ref: NbDialogRef<DeactivateDialogComponent>) {}

  dismiss() {
    this.ref.close();
  }

  confirm() {
    this.deactivate.emit();
    this.ref.close();
  }
}
