import { Component, Input, OnInit } from "@angular/core";
import { NbDialogRef, NbSortDirection, NbSortRequest, NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from "@nebular/theme";
import { StoreService } from "../../../../../core/src/lib/store/store.service";
import * as moment from "moment";

interface TreeNode<T> {
  data: T;
  children?: TreeNode<T>[];
  expanded?: boolean;
}

interface FSEntry {
  Date: Date,
  User: string,
  Action: string
}

@Component({
  selector: 'ngx-integration-change-log-dialog',
  templateUrl: 'integration-change-log.component.html',
  styleUrls: ['integration-change-log.component.scss'],
})
export class IntegrationChangeLogComponent implements OnInit{

  @Input() title!: string;
  @Input() integrationId!: number;
  defaultColumns = [ 'Date', 'User', 'Action' ];
  allColumns = [ ...this.defaultColumns ];

  dataSource?: NbTreeGridDataSource<FSEntry>;

  sortColumn?: string;
  sortDirection: NbSortDirection = NbSortDirection.NONE;

  constructor(protected ref: NbDialogRef<IntegrationChangeLogComponent>, private dataSourceBuilder: NbTreeGridDataSourceBuilder<FSEntry>, private store:StoreService) {
  }

  ngOnInit() {
    const data: TreeNode<FSEntry>[] = []
    this.store.getActivityData('OloIntegration', this.integrationId).subscribe((activityData) => {
      activityData.forEach((activity) => {
        data.push({
          data: {
            Date: moment(activity.timestamp).toDate(),
            User: activity.User.firstName + ' ' + activity.User.lastName,
            Action: activity.action
          }
        })
      })
      this.dataSource = this.dataSourceBuilder.create(data);
    })
  }

  dismiss() {
    this.ref.close();
  }

  updateSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }

}
