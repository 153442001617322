import {Component} from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by d-flex flex-row gap-2 w-100">
      <div class="version d-flex flex-row flex-grow-1">
<!--        <div class="d-flex flex-column">-->
<!--          <div>-->
<!--            Version: x.x.x-->
<!--          </div>-->
<!--          <a href="#" class="text-danger">-->
<!--            Report a Bug-->
<!--          </a>-->
<!--        </div>-->
      </div>
      <div class="d-flex flex-row gap-2">
        <a href="https://dineengine.com/" target="_blank" class="d-flex align-items-center">
          <img height="24px" width="auto"
               src="/assets/images/de-logo-horizontal-orange-dark.svg" alt="DineEngine Logo"
               title="DineEngine">
        </a>
      </div>
    </span>
    <!--    <div class="socials">-->
    <!--      <a href="#" target="_blank" class="ion ion-social-github"></a>-->
    <!--      <a href="#" target="_blank" class="ion ion-social-facebook"></a>-->
    <!--      <a href="#" target="_blank" class="ion ion-social-twitter"></a>-->
    <!--      <a href="#" target="_blank" class="ion ion-social-linkedin"></a>-->
    <!--    </div>-->
  `,
})
export class FooterComponent {
}
