import { NgModule } from '@angular/core';
import {
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbOptionModule,
  NbSelectModule,
  NbTreeGridModule
} from '@nebular/theme';

import { ThemeModule } from '@square-pos-integration/theme';
import { PagesComponent } from './pages.component';
import { MiscellaneousModule } from './miscellaneous/miscellaneous.module';
import { HomeModule } from './home/home.module';
import { ManageSellersModule } from './manage-sellers/manage-sellers.module';
import { UsersModule } from './users/users.module';
import { SettingsModule } from './settings/settings.module';
import { UserInformationModule } from './user-information/user-information.module'
import {OAuth2Module} from './auth/oauth2.module';
import {NewUserModule} from './new-user/new-user.module';
import {PagesRoutingModule} from './pages-routing.module';
import { ManageLocationsModule } from "./manage-locations/manage-locations.module";

@NgModule({
  imports: [
    ThemeModule,
    NbMenuModule,
    MiscellaneousModule,
    HomeModule,
    ManageSellersModule,
    ManageLocationsModule,
    UsersModule,
    SettingsModule,
    UserInformationModule,
    NewUserModule,
    OAuth2Module,
    NbTreeGridModule,
    NbLayoutModule,
    PagesRoutingModule,
  ],
  declarations: [
    PagesComponent,
  ],
})
export class PagesModule {
}
