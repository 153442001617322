import { Component, OnInit } from '@angular/core';
import { StoreService } from "../../../../core/src/lib/store/store.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SellerLocation } from "@libs/api-models";

@Component({
  selector: 'square-pos-integration-manage-locations',
  templateUrl: './manage-locations.component.html',
  styleUrls: ['./manage-locations.component.scss'],
})
export class ManageLocationsComponent implements OnInit {

  locationsForm = new FormGroup({});
  private _locations: SellerLocation[] = [];

  constructor(
    public store: StoreService
  ) {}

  ngOnInit(): void {
    this.store.sellerLocations$.subscribe((locations) => {
      this._locations = locations;
      Object.keys(this.locationsForm.controls).forEach(key => {
        this.locationsForm.removeControl(key);
      });
      locations.forEach((location) => {
        this.locationsForm.addControl(location.squareID, new FormControl(location.extRef, [Validators.required]));
      })
    })
  }

  updateLocations() {
    this._locations.forEach((location) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      location.extRef = this.locationsForm.get(location.squareID)?.value;
    })
    this.store.updateSellerLocations(this._locations).subscribe(() => {});
  }
}
