import { NgModule } from '@angular/core';
import { ManageLocationsComponent } from './manage-locations.component';
import {
  NbActionsModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbCardModule,
  NbIconModule,
  NbInputModule,
  NbTagModule
} from "@nebular/theme";
import { ManageLocationsRoutingModule } from "./manage-locations-routing.module";
import { ThemeModule } from "@square-pos-integration/theme";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [ManageLocationsComponent],
  imports: [
    ThemeModule,
    ManageLocationsRoutingModule,
    NbActionsModule,
    NbTagModule,
    NbCardModule,
    NbAutocompleteModule,
    NbIconModule,
    NbButtonModule,
    NbInputModule,
    ReactiveFormsModule,
  ],
})
export class ManageLocationsModule {}
