import { Component, OnInit } from '@angular/core';
import {NbRegisterComponent} from '@nebular/auth';

@Component({
  selector: 'square-pos-integration-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent extends NbRegisterComponent implements OnInit {
  ngOnInit(): void {
    this.user['role'] = 1
  }
}
