import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {NbSortDirection, NbSortRequest, NbTreeGridDataSource, NbTreeGridDataSourceBuilder} from '@nebular/theme';
import {AdminPanelService} from '../../../../core/src/lib/mock/admin-panel.service';
import {filter, map, takeWhile} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import { APIUser } from "../../../../core/src/lib/api/api.types";
import { StoreService } from "../../../../core/src/lib/store/store.service";

interface TreeNode<T> {
  data: T;
  children?: TreeNode<T>[];
  expanded?: boolean;
}

interface FSEntry {
  firstName: string;
  lastName: string;
  role: string;
  active: boolean;
  id: number
  // sellers: string[];
  // status: string;
}

@Component({
  selector: 'ngx-users',
  styleUrls: ['./users.component.scss'],
  templateUrl: './users.component.html',
})
export class UsersComponent implements OnInit, OnDestroy {

  private alive = true;
  users: APIUser[] = [];
  active = true;

  defaultColumns = [ 'firstName', 'lastName', 'role' ];
  defaultColumnLabels = [ 'First Name', 'Last Name', 'Role'];
  dataSource: NbTreeGridDataSource<FSEntry>;

  sortColumn?: string;
  sortDirection: NbSortDirection = NbSortDirection.NONE;
  options: APIUser[] = [];
  filteredOptions$: Observable<APIUser[]> = of([])

  private data: TreeNode<FSEntry>[] = [];

  @ViewChild('autoInput') input?: ElementRef<HTMLInputElement>;

  constructor(
    private dataSourceBuilder: NbTreeGridDataSourceBuilder<FSEntry>,
    private store: StoreService
  ) {
    this.dataSource = this.dataSourceBuilder.create(this.data);
  }

  ngOnInit() {
    this.store.accountManagers$.subscribe(users => {
      this.users = users;
      this.data = [];
      this.options = this.users;
      this.filteredOptions$ = of(this.options);
      for (const user of users) {
        this.data.push({
          data: {
            firstName: user.first_name,
            lastName: user.last_name,
            role: user.role.name,
            active: user.active,
            id: user.id
          }
        })
      }
      console.log(this.data)
      this.dataSource = this.dataSourceBuilder.create(this.data);
    })

  }

  ngOnDestroy() {
    this.alive = false;
  }

  updateSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }

  private filter(value: string): APIUser[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(optionValue => (optionValue.first_name.concat(optionValue.last_name)).toLowerCase().includes(filterValue));
  }

  getFilteredOptions(value: string): Observable<APIUser[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  // onChange() {
  //   if (this.input) {
  //     this.input.nativeElement.value = ``
  //     this.filteredOptions$ = this.getFilteredOptions(this.input.nativeElement.value);
  //   }
  // }

  onSelectionChange($event: APIUser) {
    this.filteredOptions$ = this.getFilteredOptions(`${$event.first_name} ${$event.last_name}`);
  }

}

@Component({
  selector: 'nb-fs-icon',
  template: `
    <nb-tree-grid-row-toggle [expanded]="expanded" *ngIf="isDir(); else fileIcon">
    </nb-tree-grid-row-toggle>
    <ng-template #fileIcon>
      <nb-icon icon="file-text-outline"></nb-icon>
    </ng-template>
  `,
})
export class FsIconComponent {
  @Input() kind!: string;
  @Input() expanded!: boolean;

  isDir(): boolean {
    return this.kind === 'dir';
  }
}
