import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-reset-password-dialog',
  templateUrl: 'inactive-integration-dialog.component.html',
  styleUrls: ['inactive-integration-dialog.component.scss'],
})
export class InactiveIntegrationDialogComponent {

  isOlo = true;

  @Input() title: string;
  @Input() sellerName: string;
  @Input() emailAddress: string;

  constructor(protected ref: NbDialogRef<InactiveIntegrationDialogComponent>) {}

  dismiss() {
    this.ref.close();
  }
}
