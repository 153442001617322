import { NgModule } from '@angular/core';
import {
  NbActionsModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbCardModule,
  NbIconModule,
  NbInputModule,
  NbOptionModule,
  NbTagModule,
  NbTreeGridModule,
} from '@nebular/theme';

import { ThemeModule } from '@square-pos-integration/theme';
import { UsersRoutingModule } from './users-routing.module';
import {FsIconComponent, UsersComponent} from './users.component';

@NgModule({
  imports: [
    ThemeModule,
    NbCardModule,
    NbButtonModule,
    UsersRoutingModule,
    NbCardModule,
    NbAutocompleteModule,
    NbActionsModule,
    NbTagModule,
    NbInputModule,
    NbTreeGridModule,
    NbIconModule,
    NbOptionModule,
    NbActionsModule
  ],
  declarations: [
    UsersComponent,
    FsIconComponent
  ],
})
export class UsersModule { }
