import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { PagesComponent } from './pages.component';
import { NotFoundComponent } from './miscellaneous/not-found/not-found.component';
import { HomeComponent } from './home/home.component';
import { ManageSellersComponent } from './manage-sellers/manage-sellers.component';
import { UsersComponent } from './users/users.component';
import { SettingsComponent } from './settings/settings.component';
import { UserInformationComponent } from './user-information/user-information.component';
import {NewUserComponent} from './new-user/new-user.component';
import {AuthGuardGuard} from './guards/auth-guard.guard';
import { PermissionsGuard } from "./guards/permissions.guard";
import { ManageLocationsComponent } from "./manage-locations/manage-locations.component";

const routes: Routes = [{
  path: '',
  component: PagesComponent,
  canActivate: [AuthGuardGuard],
  canActivateChild: [AuthGuardGuard],
  children: [
    {
      path: 'home',
      component: HomeComponent,
    },
    {
      path: 'manage-sellers',
      component: ManageSellersComponent,
      canActivate: [PermissionsGuard],
    },
    {
      path: 'manage-locations',
      component: ManageLocationsComponent,
    },
    {
      path: 'users',
      component: UsersComponent,
      canActivate: [PermissionsGuard],
    },
    {
      path: 'settings',
      component: SettingsComponent,
    },
    {
      path: 'user-information',
      component: UserInformationComponent,
    },
    {
      path: 'user-information/:id',
      component: UserInformationComponent,

    },
    {
      path: 'users/add-new-user',
      component: NewUserComponent,
    },
    {
      path: '',
      redirectTo: 'home',
      pathMatch: 'full',
    },
    {
      path: '**',
      redirectTo: '/pages/404',
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
