import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageSellersComponent } from './manage-sellers.component';

const routes: Routes = [
  {
    path: '',
    component: ManageSellersComponent,
    children: [
      {
        path: '404',
        component: ManageSellersComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManageSellersRoutingModule {
}
