import { NgModule } from '@angular/core';
import { NbButtonModule, NbCardModule, NbDialogModule, NbInputModule, NbSelectModule, NbSpinnerModule } from "@nebular/theme";

import { ThemeModule } from '@square-pos-integration/theme';
import { UserInformationRoutingModule } from './user-information-routing.module';
import { UserInformationComponent } from './user-information.component';
import { ResetPasswordDialogComponent } from './reset-password-dialog/reset-password-dialog.component';
import { ReactiveFormsModule } from "@angular/forms";

const COMPONENTS = [
  ResetPasswordDialogComponent,
];

const ENTRY_COMPONENTS = [
  ResetPasswordDialogComponent,
];

const MODULES = [
  NbDialogModule.forChild(),
];

// const SERVICES = [
// ];

@NgModule({
  imports: [
    ThemeModule,
    NbCardModule,
    NbButtonModule,
    UserInformationRoutingModule,
    NbCardModule,
    NbSelectModule,
    NbInputModule,
    NbButtonModule,
    ...MODULES,
    ReactiveFormsModule,
    NbSpinnerModule
  ],
  declarations: [
    UserInformationComponent,
    ResetPasswordDialogComponent,
    ...COMPONENTS,
  ],
  // providers: [
  //   ...SERVICES,
  // ],
  entryComponents: [
    ...ENTRY_COMPONENTS,
  ],
})
export class UserInformationModule { }
