import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import {
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
} from '@nebular/theme';

import {
  NbAuthModule,
  NbOAuth2AuthStrategy,
  NbOAuth2ResponseType,
} from '@nebular/auth';

import { OAuth2LoginComponent } from './oauth2-login/oauth2-login.component';
import { OAuth2CallbackComponent } from './oauth2-callback/oauth2-callback.component';
import { Oauth2RoutingModule } from './oauth2-routing.module';
import { ThemeModule } from '@square-pos-integration/theme';
import { AuthTitleSectionComponent } from './auth-title-section/auth-title-section.component';
import { PasswordLoginComponent } from './password-login/password-login.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LogoutComponent } from './logout/logout.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    NbAuthModule.forRoot({
      strategies: [
        NbOAuth2AuthStrategy.setup({
          name: 'square',
          clientId: 'sandbox-sq0idb-urkpyqJGgQyyZeocAW1Vig',
          clientSecret:
            'sandbox-sq0csb-8ZPprkAcdaPqHI2cr_O-bgSm914kRsa1WqPxe0EFZNU',
          authorize: {
            endpoint: 'https://connect.squareupsandbox.com/oauth2/authorize',
            responseType: NbOAuth2ResponseType.CODE,
            scope:
              'ITEMS_READ,MERCHANT_PROFILE_READ,PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS,PAYMENTS_WRITE,PAYMENTS_READ',
            redirectUri: 'http://localhost:4200/auth/oauth/callback',
          },
          redirect: {
            success: '/auth/oauth',
          },
        }),
      ],
    }),
    ThemeModule,
    NbButtonModule,
    NbCardModule,
    NbLayoutModule,
    NbAlertModule,
    NbCheckboxModule,
    Oauth2RoutingModule,
    NbInputModule,
    NbIconModule,
  ],
  declarations: [
    OAuth2LoginComponent,
    OAuth2CallbackComponent,
    PasswordLoginComponent,
    AuthTitleSectionComponent,
    CreateAccountComponent,
    ResetPasswordComponent,
    LogoutComponent,
  ],
})
export class OAuth2Module {}
