import { Component, OnDestroy } from '@angular/core';
import { NbAuthOAuth2Token, NbAuthResult, NbAuthService, NbAuthToken } from '@nebular/auth';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {environment} from '../../../../../../apps/admin/src/environments/environment';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as uuid from 'nano-uuid';

@Component({
  selector: 'nb-playground-auth',
  template: `
<!--    <nb-card>-->
<!--      <nb-card-body>-->
<!--        <p>Current User Authenticated: {{ !!token }}</p>-->
<!--        <p>Current User Token: {{ token | json }}</p>-->
        <h1 id="title" class="title">Connect your Account</h1>
        <p class="sub-title">Log in using your Square Seller Account.</p>
        <small>All permissions must be granted for integration to function properly.</small>
        <button nbButton fullWidth size="giant" status="success" (click)="login()">Connect with Square</button>
<!--      </nb-card-body>-->
<!--    </nb-card>-->
  `,
})
export class OAuth2LoginComponent implements OnDestroy {

  private destroy$ = new Subject<void>();

  constructor(private authService: NbAuthService) {
  }

  login() {
    const baseURL = `${environment.squareBaseURL}/oauth2/authorize`;
    const clientId = environment.squareClientID
    const redirectURI = `${window.location.origin}/auth/oauth2/callback`;
    const state = uuid.v4();
    localStorage.setItem('state', state);
    const scope: string[] = [
      'PAYMENTS_WRITE',
      'PAYMENTS_READ',
      'ORDERS_WRITE',
      'ORDERS_READ',
      'ITEMS_READ',
      'CUSTOMERS_READ',
      'CUSTOMERS_WRITE',
      'MERCHANT_PROFILE_READ',
      'EMPLOYEES_READ',
      'INVENTORY_READ',
    ];
    const session = false;
    window.open(`${baseURL}?client_id=${clientId}&scope=${scope.join('+')}&response_type=code&redirect_uri=${redirectURI}&session=${session}&state=${state}`, '_self')
    // this.authService
    //   .authenticate('square')
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((authResult: NbAuthResult) => {});
  }

  logout() {
    // this.authService
    //   .logout('square')
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((authResult: NbAuthResult) => {});
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
