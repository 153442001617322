import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HttpService} from './http.service';
import {ApiService} from './api.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    HttpService,
    ApiService,
  ],
})
export class ApiModule { }
