import {AfterViewInit, Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {NbJSThemeVariable, NbThemeService} from '@nebular/theme';
import {Order} from '@libs/api-models';
import * as moment from 'moment/moment';

@Component({
  selector: 'ngx-echarts-pie',
  template: `
    <div echarts [options]="options" class="echart"></div>
  `,
})
export class EchartsPieComponent implements AfterViewInit, OnDestroy, OnChanges {
  private colors: NbJSThemeVariable | undefined;
  private echarts: NbJSThemeVariable | undefined;
  options: any = {};
  themeSubscription: any;
  @Input() allOrders!: Order[]
  last30Days: string[]= [];
  isViewInitialized = false;

  constructor(private theme: NbThemeService) {
    const firstDay = moment().subtract(30, 'days')
    while (firstDay.isSameOrBefore(moment(), 'day')) {
      this.last30Days.push(firstDay.format('YYYY-MM-DD'))
      firstDay.add(1, 'days')
    }
  }

  ngAfterViewInit() {
    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
      this.colors = config.variables;
      this.echarts = (config.variables?.['echarts'] as NbJSThemeVariable);
      this.isViewInitialized = true;
      this.setOptions(this.allOrders);
    });
  }

  ngOnChanges() {
    if (this.isViewInitialized) {
      this.setOptions(this.allOrders);
    }
  }

  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
  }

  private setOptions(orders: Order[]) {

    if (this.colors && this.echarts) {
      this.options = {
        backgroundColor: this.echarts['bg'],
        color: [this.colors['dangerLight'], this.colors['successLight']],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: ['Failed Orders', 'Successful Orders'],
          textStyle: {
            color: this.echarts['textColor'],
          },
        },
        series: [
          {
            name: 'Success Rate',
            type: 'pie',
            radius: '80%',
            center: ['50%', '50%'],
            data: [
              { value: this.allOrders.filter(order => !order.success).length, name: 'Failed Orders' },
              { value: this.allOrders.filter(order => order.success).length, name: 'Successful Orders' },
            ],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: this.echarts['itemHoverShadowColor'],
              },
            },
            label: {
              normal: {
                textStyle: {
                  color: this.echarts['textColor'],
                },
              },
            },
            labelLine: {
              normal: {
                lineStyle: {
                  color: this.echarts['axisLineColor'],
                },
              },
            },
          },
        ],
      };
    }
  }

}
